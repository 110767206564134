export const circularesSecretariaObject = {
    'nombre': 'Circulares Secretaría de Hacienda',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '2025',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Circular No. 003',
                    'url': '../files/servicios/pdf/CircularesSH/2025/Circular 003.pdf'
                },
                {
                    'nombreLink':'Circular No. 002',
                    'url': '../files/servicios/pdf/CircularesSH/2025/Circular 002.pdf'
                },
                {
                    'nombreLink':'Circular No. 001',
                    'url': '../files/servicios/pdf/CircularesSH/2025/Circular 001.pdf'
                }
            ]
        },
        {
            'titulo': '2024',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Circular No. 028',
                    'url': '../files/servicios/pdf/CircularesSFP/2024/Circular 028 08112024.pdf'
                },
                {
                    'nombreLink':'Circular No. 014',
                    'url': '../files/servicios/pdf/CircularesSFP/2024/CIRCULAR%20No14.pdf'
                },
                {
                    'nombreLink':'Circular No. 013',
                    'url': '../files/servicios/pdf/CircularesSFP/2024/Circular%20No%20013.pdf'
                },
                {
                    'nombreLink':'Circular No. 008',
                    'url': '../files/servicios/pdf/CircularesSFP/2024/Circular%20008.pdf'
                },
                {
                    'nombreLink':'Circular No. 007',
                    'url': '../files/servicios/pdf/CircularesSFP/2024/Circular_007.pdf'
                }
            ]
        },
        {
            'titulo': '2023',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Circular No. 029',
                    'url': '../files/servicios/pdf/CircularesSFP/2023/Circular%20No%2029.pdf'
                },
                {
                    'nombreLink':'Circular No. 028',
                    'url': '../files/servicios/pdf/CircularesSFP/2023/Circular%20No%20028.pdf'
                },
                {
                    'nombreLink':'Circular No. 023',
                    'url': '../files/servicios/pdf/CircularesSFP/2023/Circular%20No%20023.pdf'
                },
                {
                    'nombreLink':'Circular No. 013',
                    'url': '../files/servicios/pdf/CircularesSFP/2023/Circular%20No%20013.pdf'
                },
                {
                    'nombreLink':'Circular No. 012',
                    'url': '../files/servicios/pdf/CircularesSFP/2023/Circular%20No%20012.pdf'
                },
                {
                    'nombreLink':'Circular No. 009',
                    'url': '../files/servicios/pdf/CircularesSFP/2023/Circular%20No%20009.pdf'
                },
                {
                    'nombreLink':'Circular No. 005',
                    'url': '../files/servicios/pdf/CircularesSFP/2023/Circular%20No%20005.pdf'
                }
            ]
        },
        {
            'titulo': '2022',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Circular No. 145',
                    'url': '../files/servicios/pdf/CircularesSFP/2022/Circular%20No%20145.pdf'
                },
                {
                    'nombreLink':'Circular No. 137',
                    'url': '../files/servicios/pdf/CircularesSFP/2022/Circular%20No%20137.pdf'
                },
                {
                    'nombreLink':'Circular No. 131',
                    'url': '../files/servicios/pdf/CircularesSFP/2022/Circular%20No%20131.pdf'
                }
            ]
        }
    ]
}