export const carouselImages = [
    {
        "imagen" : "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretar%C3%ADa/Refrendo-2025.png",
        "imagenMobile": "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretar%C3%ADa/Refrendo-2025-mobile.png"
    },
    {
        "imagen" : "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretaría/Impuesto-sobre-nomina-01.png"
    },
    {
        "imagen" : "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretaría/Impuesto-sobre-nomina-02.png"
    },
    {
        "imagen" : "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretaría/Impuesto-sobre-nomina-03.png"
    },
    {
        "imagen" : "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretaría/Impuesto-sobre-nomina-04.png"
    },
    {
        "imagen" : "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretaría/Impuesto-sobre-nomina-05.png"
    },
    {
        "imagen" : "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretaría/Impuesto-sobre-nomina-06.png"
    },
    {
        "imagen" : "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretaría/Impuesto-sobre-nomina-07.png"
    },
    {
        "imagen" : "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretar%C3%ADa/Situacion-fiscal-2555x500_01.png"
    },
    {
        "imagen" : "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretar%C3%ADa/Situacion-fiscal-2555x500_02.png"
    },
    {
        "imagen" : "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretar%C3%ADa/Situacion-fiscal-2555x500_03.png"
    },
    {
        "imagen" : "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretar%C3%ADa/Situacion-fiscal-2555x500_04.png"
    },
    {
        "imagen" : "https://hacienda.hidalgo.gob.mx/assets/imgs/Secretar%C3%ADa/Situacion-fiscal-2555x500_05.png"
    }
    //{
        //"imagen" : "../assets/imgs/banner_refrendo_24.jpg"
    //}
]