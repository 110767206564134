import React, { useState, useEffect } from 'react';
import './carousel.css';
import Serch from '../Serch/Serch';

const Carousel = ({ bannerData, serch, name }) => {
  const [miImages, setMiImages] = useState([]);

  useEffect(() => {
    setMiImages(bannerData);
  }, [bannerData]);

  return (
    <div id={name} className="carousel slide carouselBos" data-bs-ride="carousel">
      <div className="carousel-inner">
        {
          miImages.map((item, index) => (
            <div key={index} className={index >= 1 ? "carousel-item" : "carousel-item active"}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <div
                  className="img-carousel d-block w-100"
                  style={{
                    backgroundImage: `url("${item.imagen}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                  alt="carousel slide"
                >
                  {/* Imagen móvil */}
                  <picture>
                    <source media="(max-width: 768px)" srcSet={item.imagenMobile} />
                    <img src={item.imagen} alt="carousel image" className="d-block w-100" />
                  </picture>
                </div>
              </a>
            </div>
          ))
        }
        {
          serch ?
            <Serch />
            : null
        }
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target={'#' + name} data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target={'#' + name} data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default Carousel;
