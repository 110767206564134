import React, { useEffect, useState } from 'react';
import ModalConvocatoria from '../Modal/ModalConvocatoria'
import Carousel from '../Carousel/Carousel';
import UltimasNoticias from '../UltimasNoticias/UltimasNoticias';
import './inicio.css';
import { titular } from '../../json/titular';
import { Link } from 'react-router-dom';
import { carouselImages } from '../../json/carouselImages';
import DeInteres from '../DeInteres/DeInteres';

const Inicio = () => {
  const [isModalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closeModal = () => {
  setModalOpen(false);
  };
  
  return (
    <>
      <ModalConvocatoria isOpen={isModalOpen} onClose={closeModal} />
      <Carousel bannerData={carouselImages} serch={true} name={'banner-principal'}/>
      <div className='container'>
        <div className='row g-0'>
          <div className="col-12">
            <div className='secretaria_logo' >
              <div className='secretaria_logo_img' style={{ backgroundImage: `url("../../assets/gif/Business.gif")` }}>
              </div>
              {/* <div className='secretaria_logo_img' style={{ backgroundImage: `url("https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Finanzas.svg")` }}>
              </div> */}
            </div>
            <div className='secretaria_name'>
              <h1>SECRETARÍA DE HACIENDA</h1>
            </div>
            <div className='secretaria_funciones'>
              <h3 className='title'>FUNCIONES</h3>
              <hr className='hr-gob'/>
              <p className="text-center fs-4">Determinar la política hacendaría y controlar los fondos y valores del Estado.</p>
                <div className='btn_organigrama'>
                  <button type="button" className="btn btn-primary"><a href='../files/secretaria/OM-1175%202023%20EO%20HACIENDA.pdf' target='_blank'>Organigrama</a></button>
                  <button type="button" className="btn btn-primary"><a href='../files/servicios/pdf/ProcuraduriaFiscal/Reglamento%20Interior%20de%20la%20Secretaria%20de%20Hacienda.pdf' target='_blank'>Normatividad</a></button>
                </div>
                <div className='secretaria-redes'>
                  <a className='text-secondary' href='https://www.facebook.com/HaciendaHgo/' target="_blank"><i className="fa-brands fa-facebook-f"></i></a>
                  {/* <a href=''><i class="fa-brands fa-instagram"></i></a> */}
                  <a className='text-secondary' href='https://twitter.com/Hacienda_Hgo' target="_blank"><i className="fa-brands fa-x-twitter"></i></a>
                </div>
            </div>
          </div>
        </div>
        {/* <UltimasNoticias /> */}
        <DeInteres />
        <div className='row pb-5'>
          <div className='notes_title pt-3'>
            <h3 className='title'>TITULAR DE LA SECRETARÍA</h3>
            <hr className='hr-gob'/>
          </div>
          <div className='section_notes'>
            {
              titular.map((item, index) =>(
                  <>
                    <div key={ index } className='sec_photo'>
                      <div className='img_secretario' style={{ backgroundImage: `url("${ item.imagen }")` }}>
                      </div>
                    </div>
                    <div className='sec_container'>
                      <div className='sec_name'>
                        <h3>{ item.nombre }</h3>
                      </div>
                      <div className='sec_function'>
                        <h4>Función de la Secretaria</h4>
                        <p>{ item.funcion }</p>
                        <div className='btn_agenda'>
                          <Link to="Agenda">Conoce la agenda de la Secretaria</Link>
                        </div>
                      </div>
                    </div>
                  </>
              ))
            }
          </div>
        </div>
        <div className='row'>
          <div className='section_map'>
            <div className='map_name'>
              <h3 className='title'>UBICACIÓN</h3>
              <hr className='hr-gob'/>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3746.278042449049!2d-98.73901688508234!3d20.122444686496284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa78f2f740402e507!2sPalacio+De+Gobierno+Pachuca!5e0!3m2!1ses-419!2smx!4v1543272114023"
                    width="100%" 
                    height="300" 
                    frameborder="0" 
                    style={{border:0, marginBottom:50}} 
                    allowfullscreen>
            </iframe>
          </div>
        </div>   
      </div>
    </>
  )
}

export default Inicio